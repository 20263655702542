.markdown {
  margin-top: 1.5rem;
}

.markdown h1,
.markdown h2,
.markdown h3 {
  font-size: 1.33rem;
  font-weight: 600;
}

.markdown h3 {
  font-size: 1.25rem;
  font-weight: 600;
}

.markdown blockquote {
  margin: .5rem 1rem;
}
.markdown ol {
  padding-left: 1.5rem;
  margin-left: 0rem;
}

.markdown ul {
  padding-left: 1.5rem;
  list-style: disc outside none;
}

.markdown ul li {
  margin-left: 0.25em;
  display: list-item;
  text-align: -webkit-match-parent;
}